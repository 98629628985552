import Carousel from 'react-bootstrap/Carousel';
 import slide1 from '../../assets/img/slides/slide1.png'
 import slide2 from '../../assets/img/slides/slide2.png'
 import slide3 from '../../assets/img/slides/Slides1.png'

import slide4 from '../../assets/img/slides/Slides3.png'
import { Container } from 'react-bootstrap'

function Slider(props) {

 
  return (

<Container fluid>
<div class="row-fluid">
    <Carousel data-bs-theme="dark">
    <Carousel.Item>
      <img
        className="d-block img-fluid"
        src={slide3}
        alt="First slide"
      />
      <Carousel.Caption>
        {/* <h5>First slide label</h5>
        <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p> */}
      </Carousel.Caption>
    </Carousel.Item>
    <Carousel.Item>
      <img
        className="d-block img-fluid"
        src={slide4}
        alt="Second slide"
      />
      <Carousel.Caption>
        {/* <h5>Second slide label</h5>
        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p> */}
      </Carousel.Caption>
    </Carousel.Item>
    
  </Carousel>
  </div>
  </Container>
  );
}

export default Slider;